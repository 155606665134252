import React from 'react';
import Image from 'next/image';
import brand0 from '@/src/assets/brands/brand-0.png';
import brand1 from '@/src/assets/brands/brand-1.png';
import brand2 from '@/src/assets/brands/brand-2.png';
import brand3 from '@/src/assets/brands/brand-3.png';
import brand4 from '@/src/assets/brands/brand-4.png';

const AppBrands = () => {
  return (
    <section className="bg-white">
      <div className="m-auto max-w-6xl">
        <div
          className="flex w-full flex-wrap items-center justify-center gap-12 px-6 
         pb-[68px] md:justify-center md:gap-[92px]  lg:justify-between xl:px-[0px] "
        >
          {[brand0, brand1, brand2, brand3, brand4].map((e, idx) => {
            return (
              <div
                key={idx}
                className="flex h-[50px] w-[80px] items-center justify-center md:w-[120px] lg:w-[140px]"
              >
                <Image alt="" src={e} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AppBrands;
